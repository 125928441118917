import styles from "./index.module.scss";
import { LogoBig } from "../logoBig";
import { InstagramIcon, WebsiteIcon } from "../../icons/icons";
import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";
import { useEffect, useState } from "react";

const Footer = () => {
  const preferredColorScheme = usePrefersColorScheme();
  const isDarkColorSchemePreferred = preferredColorScheme === "dark";
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDarkColorSchemePreferred) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [isDarkColorSchemePreferred]);

  return (
    <div className={styles.footerContainer}>
      <div className={styles.line}></div>
      <div className="grid grid-flow-row-dense grid-cols-12 gap-4 mx-auto mt-20 mb-0 max-sm:gap-2 max-sm:mt-10">
        <div className="col-span-5 pl-20 max-sm:pl-10">
          <LogoBig
            className={styles.logoBig}
            fill={isDark ? "white" : "black"}
          />
        </div>
        <div className="col-span-7 grid grid-cols-subgrid">
          <div className={styles.linksContainer}>
            <a
              href="https://www.v73.it/"
              target="_blank"
              className={[styles.siteLink, "mt-4"].filter(Boolean).join(" ")}
              rel="noreferrer"
            >
              <WebsiteIcon
                className={styles.siteIcon}
                fill={isDark ? "white" : "black"}
              />{" "}
              v73.it
            </a>
            <a
              href="https://www.instagram.com/v73official?igsh=dGxxeTlmeW11OHc3"
              className={[styles.instaLink, "mt-4"].filter(Boolean).join(" ")}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon
                className={styles.instaIcon}
                fill={isDark ? "white" : "black"}
              />{" "}
              v73official
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerCopyContainer}>
        <p className={styles.footerCopy}>
          © 2024 - V73 MIRIADE S.P.A - Piazza dei Martiri 30, 80121, Napoli
          P.IVA 07631370637 - REA NA - 610434
          <br />
          <a
            href="https://www.iubenda.com/privacy-policy/38303141"
            className={styles.privacyPolicyLink}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
