export const ArrowRight = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Arrow 1"
        d="M1 5.29412H0.294118V6.70588H1V5.29412ZM13.4991 6.49913C13.7748 6.22347 13.7748 5.77653 13.4991 5.50087L9.00693 1.00866C8.73126 0.732994 8.28432 0.732994 8.00866 1.00866C7.73299 1.28432 7.73299 1.73126 8.00866 2.00693L12.0017 6L8.00866 9.99307C7.73299 10.2687 7.73299 10.7157 8.00866 10.9913C8.28432 11.267 8.73126 11.267 9.00693 10.9913L13.4991 6.49913ZM1 6.70588L13 6.70588V5.29412L1 5.29412V6.70588Z"
        fill={fill}
      />
    </svg>
  );
};

export const WebsiteIcon = ({ fill, width = 46, height = 47, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 689.28 690.32"
      fill={fill}
      width={width}
      height={height}
    >
      <g id="Livello_1-2" data-name="Livello_1">
        <path d="M329.65.35c167.57-8.32,354.32,134.29,359.5,335.2,8.19,317.38-369.66,470.59-589.55,253.08C-111.92,379.38,36.34,14.91,329.65.35ZM267.65,50.01c-96.17,8.66-229.46,149.17-224.57,252.79,94.39-151.65,196.32-22.6,213.14-19.84,50.54,8.28-28.15-51.39-33.96-100.98-4.86-41.45,18.44-108.8,58.04-127.08l-1.94-5.7c-3.57.27-7.14.54-10.71.82ZM394.09,49.22c143.15,108.13,3.93,214.59,15.33,230.65,14.86,20.94,47.13-51.74,115.65-54.05,103.13-3.48,136.13,117.16,115.85,38.64-28.6-110.71-133.15-201.56-246.82-215.24ZM336.83,73.68c-58.42,1.51-96.33,60.4-73.86,121.53,10.68,29.05,71.71,63.6,34.87,107.82-34.8,41.77-85-17.52-110.93-28.18-112.14-46.08-166,117.66-68.52,148.99,106.96,34.38,121.45-87.76,177.52-30.84,51.5,52.29-79.37,108.43-17.28,194.03,50.07,69.04,183.51,18.4,145.12-83.11-12.88-34.07-73.28-70.21-35.52-110.83,44.06-47.39,83.58,36.75,144.72,36.88,122.26.26,109.2-179.76-13.6-167.65-57.06,5.63-83.65,84.31-130.61,42.03-38.21-34.4,15.72-73.69,26.01-96.23,26.72-58.54-7.43-136.26-77.91-134.44ZM648.66,383.61c-97.28,164.83-207.61,34.93-220.62,31.32-62.6-17.37,44.09,49.14,37.35,135.82-6.75,86.93-112.65,103.23-58.39,95.52,116.85-16.6,246.01-140.45,241.66-262.66ZM43.07,402.85c9.68,110.25,116.53,218.38,225.69,236.36,14.7-10.03-39.99-32.36-42.61-95.94-3.08-74.78,60.1-102.64,46.8-119.51-14.98-18.01-39.04,20.56-64.49,32.36-66.92,31.01-120.79-4.36-165.39-53.27Z" />
      </g>
    </svg>
  );
};

export const InstagramIcon = ({ fill, width = 50, height = 50, className }) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      className={className}
      id="Livello_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1078.69 1079.71"
    >
      <g id="Livello_1-2" data-name="Livello_1">
        <path d="M779.53,0c160.45,9.86,277.59,114.07,295.08,275.9,5.35,49.52,5.4,468.58.44,522.11-14.13,152.35-114.24,261.66-267.9,277.87-48.43,5.11-487.22,5.13-535.56.02-142.83-15.09-251.53-119.7-267.73-263.6-5.18-46.05-5.1-495.9-.04-543.94C20.58,108.95,140.16,9,296.82,0h482.71ZM289.13,101.81c-102.09,8.87-172.07,72.07-186.14,174.47-5.78,42.08-5.98,472.55-.75,519.25,11.28,100.68,72.27,166.05,172.93,180.49,41.69,5.98,472.2,6.59,517.66,1.26,104.01-12.19,174.2-79.31,186.04-184.2,5.15-45.57,5.1-477.21-.67-516.84-13.88-95.39-80.45-161.08-175.87-172.74-35.02-4.28-471.25-5.34-513.2-1.69Z" />
        <path d="M814.93,190.73c80.73-12.44,101.58,98.98,34.2,120.91-92.57,30.13-115.49-108.38-34.2-120.91Z" />
        <path d="M510.08,260.66c246.28-22.18,399.19,260.77,243.07,453.01-132.21,162.8-395.25,124.26-471.79-71.18-71.37-182.22,55.7-366.25,228.73-381.83ZM529.28,359.36c-133.34,6.46-216.73,158.81-137.34,278.21,70.26,105.67,227.91,103.24,295.85-3.85,78.73-124.09-16.16-281.26-158.51-274.36Z" />
      </g>
    </svg>
  );
};

export const PlayIcon = ({ className, width = 39, height = 44 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 107 107"
      className={className}
      fill={"transparent"}
    >
      <path
        d="M43.75 36.6125L73 53.5L43.75 70.3875L43.75 36.6125Z"
        stroke="white"
      />
      <path
        d="M102.5 53.5C102.5 80.562 80.562 102.5 53.5 102.5C26.438 102.5 4.5 80.562 4.5 53.5C4.5 26.438 26.438 4.5 53.5 4.5C80.562 4.5 102.5 26.438 102.5 53.5Z"
        stroke="white"
      />
    </svg>
  );
};

export const PauseIcon = ({ fill, width = 29, height = 41, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 41"
      fill={fill}
      className={className}
    >
      <path d="M4 0V41M24 0V41" stroke="white" strokeWidth="8" />
    </svg>
  );
};
