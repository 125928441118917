import styles from "./index.module.scss";

const Logo = ({ fill }) => {
  return (
    <svg
      className={styles.logo}
      width="112"
      height="57"
      viewBox="0 0 112 57"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.0052 39.1471C64.2365 36.4084 64.6555 33.6782 65.2937 30.9442C65.931 28.2065 66.8052 25.5096 67.9097 22.85C69.0161 20.1912 70.3316 17.5994 71.8477 15.0735C73.3712 12.5439 75.1186 10.1434 77.1075 7.86553H58.8828V1.3125H85.0475V7.00901C80.8467 11.7561 77.6203 16.8776 75.3657 22.3632C73.1111 27.8516 71.8402 33.4487 71.5392 39.1471H64.0052Z"
        fill={fill}
      />
      <path
        d="M95.6262 16.9799C97.2036 16.9799 98.4902 16.8368 99.4852 16.5526C100.467 16.2664 101.241 15.8874 101.79 15.4108C102.333 14.9352 102.705 14.3843 102.903 13.761C103.117 13.1376 103.222 12.479 103.222 11.7999C103.222 9.89735 102.83 8.45558 102.071 7.46807C101.293 6.48242 100.068 5.98449 98.3853 5.98449C96.9593 5.98449 95.7739 6.52701 94.8384 7.60463C93.9001 8.68875 93.4236 10.2188 93.4236 12.1929H86.6253C86.6253 10.3711 86.9068 8.71848 87.492 7.23304C88.0736 5.74574 88.905 4.47025 89.9659 3.39728C91.038 2.32059 92.3172 1.48823 93.7933 0.889962C95.2797 0.297273 96.8998 0 98.6649 0C100.277 0 101.789 0.248037 103.191 0.743183C104.599 1.23368 105.83 1.94343 106.881 2.85568C107.929 3.77166 108.749 4.866 109.351 6.14242C109.946 7.42255 110.253 8.81974 110.253 10.3498C110.253 12.0656 109.886 13.6876 109.153 15.2353C108.429 16.7792 107.331 17.9888 105.863 18.8639C107.669 19.621 109.038 20.772 110.003 22.3104C110.954 23.8469 111.434 25.8164 111.434 28.2075C111.434 30.1082 111.112 31.8176 110.477 33.3337C109.843 34.859 108.943 36.1392 107.777 37.1824C106.613 38.2238 105.223 39.0348 103.615 39.6061C102.001 40.1775 100.218 40.4589 98.2756 40.4589C94.3572 40.4589 91.3492 39.3674 89.2032 37.1824C87.0703 35.0021 86.002 31.9625 86.002 28.0933H93.0371C93.0371 30.3033 93.5016 31.9012 94.4455 32.9082C95.3763 33.9161 96.7698 34.415 98.6017 34.415C100.138 34.415 101.424 33.8771 102.433 32.7883C103.449 31.6977 103.954 30.1445 103.954 28.1174C103.954 25.9093 103.32 24.3634 102.069 23.4846C100.806 22.6049 98.663 22.1673 95.6234 22.1673V16.9808L95.6262 16.9799Z"
        fill={fill}
      />
      <path
        d="M49.5078 9.26809C49.5078 13.6018 45.9953 17.1143 41.6626 17.1143C37.3298 17.1143 33.8164 13.6018 33.8164 9.26809C33.8164 4.9344 37.3289 1.42285 41.6626 1.42285C45.9963 1.42285 49.5078 4.93533 49.5078 9.26809ZM41.6626 5.08582C39.3522 5.08582 37.4803 6.95957 37.4803 9.26809C37.4803 11.5766 39.3522 13.4513 41.6626 13.4513C43.9729 13.4513 45.8448 11.5785 45.8448 9.26809C45.8448 6.95772 43.972 5.08582 41.6626 5.08582Z"
        fill={fill}
      />
      <path
        d="M41.99 49.7734H43.3927L40.982 56.346H39.3814L36.9707 49.7734H38.3837L40.1915 54.999L41.99 49.7734Z"
        fill={fill}
      />
      <path
        d="M47.9971 50.8376H45.5018V52.4856H47.7146V53.5307H45.5018V55.2726H47.9971V56.3465H44.1836V49.7646H47.9971V50.8376Z"
        fill={fill}
      />
      <path
        d="M54.8146 49.7646V56.3465H53.4964L50.5116 51.8363V56.3465H49.1934V49.7646H50.5116L53.4964 54.2841V49.7646H54.8146Z"
        fill={fill}
      />
      <path
        d="M59.9267 50.8376H57.4315V52.4856H59.6443V53.5307H57.4315V55.2726H59.9267V56.3465H56.1133V49.7646H59.9267V50.8376Z"
        fill={fill}
      />
      <path
        d="M65.4257 50.7907L62.4687 55.2349H65.4257V56.346H60.9434V55.3287L63.8817 50.8845H60.9434V49.7734H65.4257V50.7907Z"
        fill={fill}
      />
      <path d="M67.8631 49.7734H66.5449V56.346H67.8631V49.7734Z" fill={fill} />
      <path
        d="M73.1928 55.0942H70.5749L70.142 56.3465H68.7578L71.1211 49.7646H72.6558L75.0191 56.3465H73.6257L73.1928 55.0942ZM71.8848 51.29L70.9335 54.0398H72.8351L71.8838 51.29H71.8848Z"
        fill={fill}
      />
      <path
        d="M12.6639 39.1471L0 1.31348H8.33295L16.4829 27.8656L24.5892 1.31348H32.7001L20.0381 39.1471H12.6639Z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
