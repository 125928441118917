import styles from "./index.module.scss";
import { forwardRef } from "react";

const BlockContainer = forwardRef(
  ({ children, className = null, isHero = false }, ref) => {
    return (
      <section
        ref={ref}
        className={
          isHero
            ? [
                "grid-container grid grid-cols-12 gap-8",
                styles.heroContainer,
                className,
              ]
                .filter(Boolean)
                .join(" ")
            : [styles.blockContainer, className, "mx-auto"]
                .filter(Boolean)
                .join(" ")
        }
      >
        {children}
      </section>
    );
  },
);

export default BlockContainer;
