import BlockContainer from "../containers/block";
import Hero from "../components/hero";
import Video from "../components/video";
import Phrase from "../components/phrase";
import Photos from "../containers/photos";
import Footer from "../components/footer";

function App() {
  return (
    <>
      <BlockContainer isHero={true}>
        <Hero />
      </BlockContainer>
      <BlockContainer className="pt-32 max-sm:pt-10">
        <Video />
      </BlockContainer>
      <BlockContainer>
        <Phrase />
      </BlockContainer>
      <BlockContainer className="pb-10">
        <Photos />
      </BlockContainer>
      <BlockContainer className="pt-20 pb-0 max-sm:pt-0">
        <Footer />
      </BlockContainer>
    </>
  );
}

export default App;
