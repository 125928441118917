import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";

const Phrase = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const preferredColorScheme = usePrefersColorScheme();
  const isDarkColorSchemePreferred = preferredColorScheme === "dark";
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isDarkColorSchemePreferred) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [isDarkColorSchemePreferred]);

  return (
    <div
      className={[styles.phrase, "flex justify-end"].filter(Boolean).join(" ")}
    >
      <div
        className={[styles.phraseContainer, "col-span-7"]
          .filter(Boolean)
          .join(" ")}
      >
        {!isDark && screenWidth < 500 ? (
          <video playsInline autoPlay muted>
            <source src="./Composizione_1.5.mp4" type="video/mp4" />
          </video>
        ) : null}

        {isDark && screenWidth < 500 ? (
          <video playsInline autoPlay muted>
            <source src="./Composizione_1.7.mp4" type="video/mp4" />
          </video>
        ) : null}

        {!isDark && screenWidth > 500 ? (
          <video playsInline autoPlay muted>
            <source src="./Composizione_2.mp4" type="video/mp4" />
          </video>
        ) : null}

        {isDark && screenWidth > 500 ? (
          <video playsInline autoPlay muted>
            <source src="./Composizione_2.3.mp4" type="video/mp4" />
          </video>
        ) : null}
      </div>
    </div>
  );
};

export default Phrase;
