export const LogoBig = ({ fill, width = 158, height = 82, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 158 82"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Livello_1">
        <path
          id="Vector"
          d="M18.2645 55.6869L0.399414 2.31445H12.1548L23.6521 39.7719L35.0877 2.31445H46.5299L28.6674 55.6869H18.2645Z"
          fill={fill}
        />
        <g id="Clip path group">
          <mask
            id="mask0_44_4621"
            maskUnits="userSpaceOnUse"
            x="-33"
            y="-16"
            width="224"
            height="112"
          >
            <g id="clippath">
              <path
                id="Vector_2"
                d="M190.448 -15.5176H-32.4448V95.9282H190.448V-15.5176Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask0_44_4621)">
            <g id="Group">
              <path
                id="Vector_3"
                d="M90.6925 55.6872C91.0189 51.8238 91.6099 47.9722 92.5102 44.1153C93.4093 40.2532 94.6425 36.4487 96.2007 32.6967C97.7615 28.946 99.6172 25.2896 101.756 21.7263C103.905 18.1577 106.37 14.7713 109.176 11.5579H83.4663V2.31348H120.377V10.3496C114.451 17.0464 109.9 24.2713 106.719 32.01C103.538 39.7526 101.746 47.6485 101.321 55.6872H90.6925Z"
                fill={fill}
              />
              <path
                id="Vector_4"
                d="M135.3 24.4157C137.525 24.4157 139.34 24.2139 140.744 23.8129C142.129 23.4092 143.221 22.8745 143.995 22.2022C144.762 21.5312 145.286 20.7541 145.565 19.8747C145.866 18.9954 146.016 18.0662 146.016 17.1082C146.016 14.4242 145.463 12.3903 144.392 10.9972C143.294 9.60676 141.565 8.90432 139.192 8.90432C137.18 8.90432 135.508 9.66966 134.188 11.1899C132.865 12.7193 132.192 14.8777 132.192 17.6626H122.602C122.602 15.0926 122.999 12.7612 123.825 10.6657C124.645 8.56751 125.818 6.76816 127.315 5.2545C128.827 3.73561 130.632 2.56137 132.714 1.7174C134.811 0.881281 137.096 0.461914 139.586 0.461914C141.86 0.461914 143.994 0.811823 145.971 1.51033C147.958 2.20229 149.695 3.20353 151.177 4.49047C152.655 5.78265 153.812 7.32645 154.661 9.12711C155.501 10.933 155.934 12.904 155.934 15.0625C155.934 17.483 155.416 19.7712 154.382 21.9545C153.361 24.1326 151.812 25.8389 149.74 27.0734C152.288 28.1415 154.22 29.7653 155.581 31.9355C156.923 34.1031 157.6 36.8814 157.6 40.2547C157.6 42.936 157.146 45.3474 156.25 47.4862C155.356 49.6381 154.086 51.444 152.441 52.9157C150.799 54.3848 148.837 55.5289 146.57 56.3349C144.293 57.1408 141.778 57.5379 139.037 57.5379C133.51 57.5379 129.266 55.998 126.239 52.9157C123.23 49.8399 121.723 45.5518 121.723 40.0935H131.647C131.647 43.2112 132.303 45.4654 133.634 46.886C134.947 48.3079 136.913 49.0116 139.497 49.0116C141.665 49.0116 143.479 48.2528 144.902 46.7169C146.336 45.1783 147.047 42.9871 147.047 40.1276C147.047 37.0125 146.153 34.8318 144.388 33.592C142.607 32.3509 139.584 31.7337 135.296 31.7337V24.417L135.3 24.4157Z"
                fill={fill}
              />
              <path
                id="Vector_5"
                d="M70.2411 13.5361C70.2411 19.6497 65.286 24.6048 59.1737 24.6048C53.0614 24.6048 48.105 19.6497 48.105 13.5361C48.105 7.42254 53.0601 2.46875 59.1737 2.46875C65.2873 2.46875 70.2411 7.42385 70.2411 13.5361ZM59.1737 7.63615C55.9144 7.63615 53.2737 10.2795 53.2737 13.5361C53.2737 16.7928 55.9144 19.4374 59.1737 19.4374C62.433 19.4374 65.0737 16.7954 65.0737 13.5361C65.0737 10.2769 62.4316 7.63615 59.1737 7.63615Z"
                fill={fill}
              />
              <path
                id="Vector_6"
                d="M59.6369 70.6777H61.6158L58.215 79.9497H55.957L52.5562 70.6777H54.5495L57.0998 78.0494L59.6369 70.6777Z"
                fill={fill}
              />
              <path
                id="Vector_7"
                d="M68.1112 72.1787H64.5911V74.5036H67.7128V75.9779H64.5911V78.4351H68.1112V79.9501H62.7314V70.665H68.1112V72.1787Z"
                fill={fill}
              />
              <path
                id="Vector_8"
                d="M77.7259 70.666V79.9511H75.8663L71.6555 73.5885V79.9511H69.7959V70.666H71.6555L75.8663 77.0417V70.666H77.7259Z"
                fill={fill}
              />
              <path
                id="Vector_9"
                d="M84.9388 72.1787H81.4187V74.5036H84.5404V75.9779H81.4187V78.4351H84.9388V79.9501H79.5591V70.665H84.9388V72.1787Z"
                fill={fill}
              />
              <path
                id="Vector_10"
                d="M92.6968 72.1128L88.5254 78.3823H92.6968V79.9497H86.3735V78.5147L90.5187 72.2451H86.3735V70.6777H92.6968V72.1128Z"
                fill={fill}
              />
              <path
                id="Vector_11"
                d="M96.135 70.6777H94.2754V79.9497H96.135V70.6777Z"
                fill={fill}
              />
              <path
                id="Vector_12"
                d="M103.654 78.1845H99.9608L99.3501 79.9511H97.3975L100.731 70.666H102.896L106.23 79.9511H104.265L103.654 78.1845ZM101.809 72.8179L100.467 76.6971H103.149L101.807 72.8179H101.809Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_44_4621">
          <rect width="158" height="81.8214" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
