import styles from "./index.module.scss";
import Photo from "../../components/photo";
import { ArrowRight } from "../../icons/icons";
import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/useOnScreen";
import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";

const Photos = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const n01Photo = useRef(null);
  const n01isVisible = useOnScreen(n01Photo);

  const n05Photo = useRef(null);
  const n05isVisible = useOnScreen(n05Photo);

  const n12Photo = useRef(null);
  const n12isVisible = useOnScreen(n12Photo);

  const n15Photo = useRef(null);
  const n15isVisible = useOnScreen(n15Photo);

  const n18Photo = useRef(null);
  const n18isVisible = useOnScreen(n18Photo);

  const n22Photo = useRef(null);
  const n22isVisible = useOnScreen(n22Photo);

  const n30Photo = useRef(null);
  const n30isVisible = useOnScreen(n30Photo);

  const n34Photo = useRef(null);
  const n34isVisible = useOnScreen(n34Photo);

  const n43Photo = useRef(null);
  const n43isVisible = useOnScreen(n43Photo);

  const n54Photo = useRef(null);
  const n54isVisible = useOnScreen(n54Photo);

  const n61Photo = useRef(null);
  const n61isVisible = useOnScreen(n61Photo);

  const n68Photo = useRef(null);
  const n68isVisible = useOnScreen(n68Photo);

  const preferredColorScheme = usePrefersColorScheme();
  const isDarkColorSchemePreferred = preferredColorScheme === "dark";
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDarkColorSchemePreferred) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [isDarkColorSchemePreferred]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={[
        styles.container,
        "grid grid-flow-row-dense grid-cols-12 gap-4 max-sm:gap-2 mx-auto",
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <div className="col-span-4">
        <Photo
          ref={n01Photo}
          model="Visionary"
          number="01"
          visible={n01isVisible}
          screenWidth={screenWidth}
        >
          <img src="./images/VISIONARY.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Verve" number="02" screenWidth={screenWidth}>
          <img src="./images/VERVE.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Valentine" number="03" screenWidth={screenWidth}>
          <img src="./images/VALENTINE.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Vagabonda"
          number="04"
          screenWidth={screenWidth}
        >
          <img src="./images/VAGABONDA.jpg"></img>
        </Photo>
        <Photo
          model="Valiant"
          number="06"
          className="col-span-4"
          screenWidth={screenWidth}
        >
          <img src="./images/VALIANT.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          ref={n05Photo}
          model="Vibrant"
          number="05"
          bigCard={true}
          visible={n05isVisible}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/pattina-simone-73bs8fb01?variant=48733049422166"
        >
          <img src="./images/VIBRANT.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          model="Vital"
          number="07"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/pretty-grazia-73bs8c405?variant=48733042540886"
        >
          <img src="./images/VITAL.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Vistosa"
          number="08"
          screenWidth={screenWidth}
        >
          <img src="./images/VISTOSA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vocalist"
          number="09"
          screenWidth={screenWidth}
        >
          <img src="./images/VOCALIST.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Veejay" number="10" screenWidth={screenWidth}>
          <img src="./images/VEEJAY.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Vivacious" number="11" screenWidth={screenWidth}>
          <img src="./images/VIVACIOUS.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo
          ref={n12Photo}
          model="Voyager"
          number="12"
          visible={n12isVisible}
          screenWidth={screenWidth}
        >
          <img src="./images/VOYAGER.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Vampire"
          number="13"
          screenWidth={screenWidth}
        >
          <img src="./images/VAMPIRE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Volpe"
          number="15"
          ref={n15Photo}
          visible={n15isVisible}
          screenWidth={screenWidth}
        >
          <img src="./images/VOLPE.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          model="Vehement"
          number="14"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/shopping-harriet-73bs8g201?variant=48733057450326"
        >
          <img src="./images/VEHEMENT.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8">
        <Photo
          className="col-span-8"
          model="Vigorous"
          number="16"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/pretty-anne-73bs8c101?variant=48733040116054"
        >
          <img src="./images/VIGOROUS.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vindictive"
          number="19"
          screenWidth={screenWidth}
        >
          <img src="./images/VINDICTIVE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Venezia"
          number="20"
          screenWidth={screenWidth}
        >
          <img src="./images/VENEZIA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Venerable"
          number="22"
          ref={n22Photo}
          screenWidth={screenWidth}
          visible={n22isVisible}
        >
          <img src="./images/VENERABLE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vacanziera"
          number="23"
          screenWidth={screenWidth}
        >
          <img src="./images/VACANZIERA.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Vexing"
          number="17"
          screenWidth={screenWidth}
        >
          <img src="./images/VEXING.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vaga"
          number="18"
          ref={n18Photo}
          visible={n18isVisible}
          screenWidth={screenWidth}
        >
          <img src="./images/VAGA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Visceral"
          number="21"
          screenWidth={screenWidth}
        >
          <img src="./images/VISCERAL.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Verbosa"
          number="24"
          screenWidth={screenWidth}
        >
          <img src="./images/VERBOSA.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8">
        <Photo
          className="col-span-8"
          model="Vinta"
          number="25"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/sottospalla-amelia-73bs8bu06?_pos=4&_sid=b9c4cfc63&_ss=r"
        >
          <img src="./images/VINTA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Valorosa"
          number="28"
          screenWidth={screenWidth}
        >
          <img src="./images/VALOROSA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Volleyball"
          number="29"
          screenWidth={screenWidth}
        >
          <img src="./images/VOLLEYBALL.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Versatile"
          number="26"
          screenWidth={screenWidth}
        >
          <img src="./images/VERSATILE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vicious"
          number="27"
          screenWidth={screenWidth}
        >
          <img src="./images/VICIOUS.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vocal"
          number="30"
          ref={n30Photo}
          screenWidth={screenWidth}
          visible={n30isVisible}
        >
          <img src="./images/VOCAL.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Virile"
          number="31"
          screenWidth={screenWidth}
        >
          <img src="./images/VIRILE.jpg"></img>
        </Photo>
        <Photo
          className={[styles.cardFix, "col-span-4"].filter(Boolean).join(" ")}
          model="Vamp"
          number="33"
          screenWidth={screenWidth}
        >
          <img src="./images/VAMP.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          model="Vulnerable"
          number="32"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/pattina-simone-73bs8fb01?variant=48733049356630"
        >
          <img src="./images/VULNERABLE.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8 content-start">
        <Photo
          className="col-span-4"
          style={{ marginTop: "-20.4%" }}
          model="Villain"
          number="34"
          ref={n34Photo}
          screenWidth={screenWidth}
          visible={n34isVisible}
        >
          <img src="./images/VILLAIN.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vacillating"
          number="35"
          screenWidth={screenWidth}
        >
          <img src="./images/VACILLATING.jpg"></img>
        </Photo>
        <Photo
          className="col-span-8"
          model="Vain"
          number="37"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/secchiello-amelia-73bs8bu07?_pos=2&_sid=b9c4cfc63&_ss=r"
        >
          <img src="./images/VAIN.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4 content-start">
        <Photo
          className="col-span-4"
          model="Vispa"
          number="36"
          screenWidth={screenWidth}
        >
          <img src="./images/VISPA.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Victorious"
          number="38"
          screenWidth={screenWidth}
        >
          <img src="./images/VICTORIOUS.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Venturous"
          number="39"
          screenWidth={screenWidth}
        >
          <img src="./images/VENTUROUS.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Virgin" number="40" screenWidth={screenWidth}>
          <img src="./images/VIRGIN.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Volubile" number="41" screenWidth={screenWidth}>
          <img src="./images/VOLUBILE.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Velenosa" number="42" screenWidth={screenWidth}>
          <img src="./images/VELENOSA.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          model="Vexed"
          number="43"
          bigCard={true}
          ref={n43Photo}
          visible={n43isVisible}
          screenWidth={screenWidth}
          link="https://www.v73.it/collections/new-collection"
        >
          <img src="./images/VEXED.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Viva"
          number="44"
          screenWidth={screenWidth}
        >
          <img src="./images/VIVA.jpg"></img>
        </Photo>
        <Photo
          className={[styles.cardFix, "col-span-4"].filter(Boolean).join(" ")}
          model="Vanilla"
          number="45"
          screenWidth={screenWidth}
        >
          <img src="./images/VANILLA.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid col-span-4">
        <div className="col-span-4">
          <Photo model="Vigilant" number="46" screenWidth={screenWidth}>
            <img src="./images/VIGILANT.jpg"></img>
          </Photo>
        </div>
        <div className="col-span-4">
          <Photo
            className={styles.mt03Mobile}
            model="Variform"
            number="49"
            screenWidth={screenWidth}
          >
            <img src="./images/VARIFORM.jpg"></img>
          </Photo>
        </div>
        <div className="col-span-4">
          <Photo
            className={styles.mt03Mobile}
            model="Vorace"
            number="51"
            screenWidth={screenWidth}
          >
            <img src="./images/VORACE.jpg"></img>
          </Photo>
        </div>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8">
        <Photo
          className="col-span-4"
          model="Voluptuous"
          number="47"
          screenWidth={screenWidth}
        >
          <img src="./images/VOLUPTUOUS.jpg"></img>
        </Photo>
        <Photo
          className={["col-span-4"].filter(Boolean).join(" ")}
          style={{ marginTop: "-21%" }}
          model="Virtuos"
          number="48"
          screenWidth={screenWidth}
        >
          <img src="./images/VIRTUOS.jpg"></img>
        </Photo>
        <Photo
          className={[styles.mt1Mobile, "col-span-8"].filter(Boolean).join(" ")}
          model="Vegetating"
          number="50"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/shopping-martha-73bs8gx01?variant=48733053485398"
        >
          <img src="./images/VEGETATING.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Voyeur" number="52" screenWidth={screenWidth}>
          <img src="./images/VOYEUR.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Volteggiante" number="53" screenWidth={screenWidth}>
          <img src="./images/VOLTEGGIANTE.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo
          model="Venus"
          number="54"
          screenWidth={screenWidth}
          ref={n54Photo}
          visible={n54isVisible}
        >
          <img src="./images/VENUS.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8">
        <Photo
          className="col-span-8"
          model="Vanguard"
          number="55"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/products/pattina-simone-73bs8fb01?variant=48733049586006"
        >
          <img src="./images/VANGUARD.jpg"></img>
        </Photo>
        <Photo
          className={["col-span-4"].filter(Boolean).join(" ")}
          model="Videographer"
          number="58"
          screenWidth={screenWidth}
        >
          <img src="./images/VIDEOGRAPHER.jpg"></img>
        </Photo>
        <Photo
          className={["col-span-4"].filter(Boolean).join(" ")}
          model="Vertigo"
          number="59"
          screenWidth={screenWidth}
        >
          <img src="./images/VERTIGO.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Verace"
          number="56"
          screenWidth={screenWidth}
        >
          <img src="./images/VERACE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vacant"
          number="57"
          screenWidth={screenWidth}
        >
          <img src="./images/VACANT.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Volcanic"
          number="60"
          screenWidth={screenWidth}
        >
          <img src="./images/VOLCANIC.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo
          model="Veiled"
          number="61"
          ref={n61Photo}
          visible={n61isVisible}
          screenWidth={screenWidth}
        >
          <img src="./images/VEILED.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Ventriloqua" number="62" screenWidth={screenWidth}>
          <img src="./images/VENTRILOQUA.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-4">
        <Photo model="Vogueging" number="63" screenWidth={screenWidth}>
          <img src="./images/VOGUEGING.jpg"></img>
        </Photo>
      </div>
      <div className="col-span-8">
        <Photo
          model="VIP"
          number="64"
          bigCard={true}
          screenWidth={screenWidth}
          link="https://www.v73.it/collections/new-collection"
        >
          <img src="./images/VIP.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Voodoo"
          number="65"
          screenWidth={screenWidth}
        >
          <img src="./images/VOODOO.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vlogger"
          number="66"
          screenWidth={screenWidth}
        >
          <img src="./images/VLOGGER.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-4">
        <Photo
          className="col-span-4"
          model="Veggente"
          number="67"
          screenWidth={screenWidth}
        >
          <img src="./images/VEGGENTE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Venerated"
          number="69"
          screenWidth={screenWidth}
        >
          <img src="./images/VENERATED.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Veloce"
          number="70"
          screenWidth={screenWidth}
        >
          <img src="./images/VELOCE.jpg"></img>
        </Photo>
        <Photo
          className="col-span-4"
          model="Vera"
          number="73"
          screenWidth={screenWidth}
        >
          <img src="./images/VERA.jpg"></img>
        </Photo>
      </div>
      <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8 content-start">
        <Photo
          className="col-span-8"
          model="Vivid"
          number="68"
          bigCard={true}
          screenWidth={screenWidth}
          visible={n68isVisible}
          ref={n68Photo}
          link="https://www.v73.it/collections/new-collection"
        >
          <img src="./images/VIVID.jpg"></img>
        </Photo>
        <div className="grid grid-cols-subgrid gap-4 max-sm:gap-2 col-span-8">
          <Photo
            className="col-span-4"
            model="Va va voom"
            number="71"
            screenWidth={screenWidth}
          >
            <img src="./images/VA-VA-VOOM.jpg"></img>
          </Photo>
          <Photo
            className="col-span-4"
            model="Victorian"
            number="72"
            screenWidth={screenWidth}
          >
            <img src="./images/VICTORIAN.jpg"></img>
          </Photo>
        </div>
        <div
          className={[styles.ctaContainer, "col-span-full"]
            .filter(Boolean)
            .join(" ")}
        >
          <a
            href="https://www.v73.it/collections/new-collection"
            className={styles.cta}
            target="_blank"
            rel="noreferrer"
          >
            <span className={styles.ctaIconContainer}>
              <ArrowRight fill={isDark ? "white" : "black"} />
            </span>{" "}
            Scopri la nuova collezione V°73
          </a>
        </div>
      </div>
      <div
        className={[styles.ctaContainerMobile, "col-span-12 pt-8 pb-0"]
          .filter(Boolean)
          .join(" ")}
      >
        <a
          href="https://www.v73.it/collections/new-collection"
          className={styles.cta}
          target="_blank"
          rel="noreferrer"
        >
          <span className={styles.ctaIconContainer}>
            <ArrowRight
              className={styles.ctaIcon}
              fill={isDark ? "white" : "black"}
            />
          </span>{" "}
          Scopri la nuova collezione V°73
        </a>
      </div>
    </div>
  );
};

export default Photos;
