import styles from "./index.module.scss";
import Logo from "../logo";
import { useEffect, useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ArrowRight } from "../../icons/icons";
import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme";

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [counter, setCounter] = useState(0);
  const [intervalTime, setIntervalTime] = useState(65);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const textRef = useRef(null);
  const totalImages = 73;
  const preferredColorScheme = usePrefersColorScheme();
  const isDarkColorSchemePreferred = preferredColorScheme === "dark";
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDarkColorSchemePreferred) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [isDarkColorSchemePreferred]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseMove = (e) => {
    const textRect = textRef.current.getBoundingClientRect();
    const relativeX = e.clientX - textRect.left;
    const percentageX = relativeX / textRect.width;
    const index = Math.floor(percentageX * totalImages);
    if (index < totalImages) {
      setHoverIndex(index);
      setMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  useGSAP(() => {
    const tl = gsap.timeline({
      defaults: { ease: "none", duration: 3 }, // Setup some defautls that get used by all tweens on the timemine
      scrollTrigger: {
        // Enable to ScrollTrigger to see how it would work on scroll
        trigger: ".panelsContainer",
        start: "top top",
        end: "bottom top",
        pin: true,
        scrub: true,
        markers: false,
      },
    });
    tl.from(".panelMask", {
      yPercent: 100, // Aniamte the white panel from offscreen to on screen
    });
    tl.from(
      ".panelAlt",
      {
        clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)", // animate the CSS clip-path
      },
      "<",
    ); // Start this tween at the same time as te previous one
    tl.to(".video", {
      opacity: 0, // Aniamte the orange panel from offscreen to on screen
      ease: "steps(1)",
      duration: 1,
    });
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < totalImages) {
        setCounter((prevCounter) => prevCounter + 1);
      }
      if (counter < 65) {
        setIntervalTime((prevTime) => Math.min(prevTime + 0.1, 1000));
      } else {
        setIntervalTime((prevTime) => Math.min(prevTime + 100, 1000));
      }
    }, intervalTime);
    return () => clearInterval(interval);
  }, [counter, intervalTime, totalImages]);

  return (
    <div
      className={[styles.panelsContainer, "panelsContainer"]
        .filter(Boolean)
        .join(" ")}
    >
      <div className={styles.both}>
        <div
          className={[styles.panel, "panel grid grid-cols-12 gap-4"]
            .filter(Boolean)
            .join(" ")}
        >
          <Logo fill="white" />
          <div
            className={[styles.lineLeft, "lineLeft"].filter(Boolean).join(" ")}
          ></div>
          <div
            className={[styles.lineBottom, "lineBottom"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[styles.lineRight, "lineRight"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[styles.lineTop, "lineTop"].filter(Boolean).join(" ")}
          ></div>
          <div
            className={[styles.lineTopLeft, "lineTopLeft"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[
              styles.blockContent,
              "grid col-span-12 grid-cols-subgrid pt-80 pl-20 pr-20 max-lg:pt-96 max-sm:pl-10 max-sm:pr-10",
            ]
              .filter(Boolean)
              .join(" ")}
          >
            <div
              className={[styles.titleContainer, "col-span-12"]
                .filter(Boolean)
                .join(" ")}
            >
              <h1 className={[styles.title, "title"].filter(Boolean).join(" ")}>
                <span className={styles.counter}>{counter}</span>Versions of
                You.
              </h1>
              <h1
                className={[styles.title, styles.thin, "title"]
                  .filter(Boolean)
                  .join(" ")}
              >
                and counting.
              </h1>
            </div>
            <div
              className={[
                "col-span-7 max-sm:col-span-12 max-md:col-span-12",
                styles.contentContainer,
              ]
                .filter(Boolean)
                .join(" ")}
            >
              <p
                className={[styles.content, "pt-4 max-sm:pt-0"]
                  .filter(Boolean)
                  .join(" ")}
              >
                Ogni donna è un universo di infinite versioni di sé stessa.
                Tutte diverse. Tutte uniche. Tutte da esprimere con orgoglio.
              </p>
            </div>
            <div
              className={[
                "col-span-5 max-sm:col-span-12 max-md:col-span-12",
                styles.ctaContainer,
              ]
                .filter(Boolean)
                .join(" ")}
            >
              <a
                href="https://www.v73.it/collections/new-collection"
                className={[styles.cta, "pt-24 max-sm:pt-0"]
                  .filter(Boolean)
                  .join(" ")}
                target="_blank"
                rel="noreferrer"
              >
                <span className={styles.ctaIcon}>
                  <ArrowRight fill="white" />
                </span>{" "}
                Scopri la nuova collezione V°73
              </a>
            </div>
          </div>
        </div>
        <div
          className={[styles.panel, styles.panelMask, "panel panelMask"]
            .filter(Boolean)
            .join(" ")}
        ></div>
        <div
          className={[
            styles.panel,
            styles.panelAlt,
            "panel panelAlt grid grid-cols-12 gap-4",
          ]
            .filter(Boolean)
            .join(" ")}
        >
          <Logo fill={isDark ? "white" : "black"} />
          <div
            className={[styles.lineLeft, "lineLeft"].filter(Boolean).join(" ")}
          ></div>
          <div
            className={[styles.lineBottom, "lineBottom"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[styles.lineRight, "lineRight"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[styles.lineTop, "lineTop"].filter(Boolean).join(" ")}
          ></div>
          <div
            className={[styles.lineTopLeft, "lineTopLeft"]
              .filter(Boolean)
              .join(" ")}
          ></div>
          <div
            className={[
              styles.blockContent,
              "grid col-span-12 grid-cols-subgrid pt-80 pl-20 pr-20 max-lg:pt-96 max-sm:pl-10 max-sm:pr-10",
            ]
              .filter(Boolean)
              .join(" ")}
          >
            <div
              className={[styles.titleContainer, "col-span-12"]
                .filter(Boolean)
                .join(" ")}
            >
              <div
                className={styles.textContainer}
                onMouseEnter={() => setHoverIndex(0)}
                onMouseLeave={() => setHoverIndex(null)}
                onMouseMove={handleMouseMove}
              >
                <h1
                  className={[styles.title, "title"].filter(Boolean).join(" ")}
                  ref={textRef}
                >
                  <span className={styles.counter}>{counter}</span>Versions of
                  You.
                </h1>
                {hoverIndex !== null && (
                  <img
                    src={`./thumbs/${hoverIndex + 1}.jpg`}
                    alt={`image ${hoverIndex + 1}`}
                    className={styles.hoverImage}
                    style={{
                      left: `${mousePosition.x}px`,
                      top: `${mousePosition.y}px`,
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <h1
                  className={[styles.title, styles.thin, "title"]
                    .filter(Boolean)
                    .join(" ")}
                >
                  and counting.
                </h1>
              </div>
            </div>
            <div
              className={[
                "col-span-7 max-sm:col-span-12 max-md:col-span-12",
                styles.contentContainer,
              ]
                .filter(Boolean)
                .join(" ")}
            >
              <p
                className={[styles.content, "pt-4 max-sm:pt-0"]
                  .filter(Boolean)
                  .join(" ")}
              >
                Ogni donna è un universo di infinite versioni di sé stessa.
                Tutte diverse. Tutte uniche. Tutte da esprimere con orgoglio.
              </p>
            </div>
            <div
              className={[
                "col-span-5 max-sm:col-span-12 max-md:col-span-12",
                styles.ctaContainer,
              ]
                .filter(Boolean)
                .join(" ")}
            >
              <a
                href="https://www.v73.it/collections/new-collection"
                className={[styles.cta, "pt-24 max-sm:pt-0"]
                  .filter(Boolean)
                  .join(" ")}
                target="_blank"
                rel="noreferrer"
              >
                <span className={styles.ctaIcon}>
                  <ArrowRight fill={isDark ? "white" : "black"} />
                </span>{" "}
                Scopri la nuova collezione V°73
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.videoOverlay}></div>
        {screenWidth > 500 && (
          <video
            playsInline
            className={[styles.video, "video"].filter(Boolean).join(" ")}
            autoPlay
            loop
            muted
          >
            <source src="./square_hero_desktop.mp4" type="video/mp4" />
          </video>
        )}
        {screenWidth <= 500 && (
          <video
            autoPlay
            muted
            playsInline
            className={[styles.video, "video"].filter(Boolean).join(" ")}
            loop
          >
            <source src="./vertical_hero_mobile.mp4" type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
};

export default Hero;
