import styles from "./index.module.scss";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { ArrowRight } from "../../icons/icons";

const Photo = forwardRef(function Photo(
  {
    children,
    style,
    className = null,
    number,
    model,
    bigCard = false,
    visible = false,
    screenWidth,
    link = "#",
  },
  ref,
) {
  const [clicked, setClicked] = useState(false);

  const setClickedTrue = useCallback(() => {
    if (screenWidth >= 500) {
      return setClicked(true);
    }
  }, [screenWidth]);

  const setClickedFalse = useCallback(() => {
    if (screenWidth >= 500) {
      return setClicked(false);
    }
  }, [screenWidth]);

  const toggleClicked = useCallback(() => {
    if (screenWidth <= 500) {
      return setClicked((c) => !c);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (screenWidth <= 500) {
      if (visible) {
        setClicked(true);
        setTimeout(() => {
          setClicked(false);
        }, 1000);
      }
    }
  }, [visible, screenWidth]);

  return (
    <div
      ref={ref}
      onMouseEnter={setClickedTrue}
      onMouseLeave={setClickedFalse}
      onClick={toggleClicked}
      className={[styles.flipCard, clicked ? styles.cardHover : null, className]
        .filter(Boolean)
        .join(" ")}
      style={style}
    >
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>{children}</div>
        <div className={styles.flipCardBack}>
          <div className={styles.cardContent}>
            <p
              className={[bigCard ? styles.cardNumberBig : styles.cardNumber]
                .filter(Boolean)
                .join(" ")}
            >
              V°{number}
            </p>
            <p className={[bigCard ? styles.cardModelBig : styles.cardModel]}>
              {model}
            </p>
          </div>
          {bigCard && (
            <a
              href={link}
              target="_blank"
              className={styles.ctaBig}
              rel="noreferrer"
            >
              <span className={styles.ctaIcon}>
                <ArrowRight fill="white" className={styles.arrowRight} />
              </span>{" "}
              Vai allo shop
            </a>
          )}
          <div className={styles.cardOverlay}></div>
          {children}
        </div>
      </div>
    </div>
  );
});

export default Photo;
