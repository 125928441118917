import styles from "./index.module.scss";
import { PauseIcon, PlayIcon } from "../../icons/icons";
import { useEffect, useRef, useState } from "react";

const Video = () => {
  const vidRef = useRef(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const [pauseIconVisible, setPauseIconVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setVideoIsPaused(false);
    setVideoIsPlaying(true);
  };

  const handlePauseVideo = () => {
    vidRef.current.pause();
    setVideoIsPaused(true);
    setVideoIsPlaying(false);
  };

  const endedCallback = () => {
    vidRef.current.currentTime = 0;
    setVideoIsPaused(false);
    setVideoIsPlaying(false);
  };

  return (
    <div
      className={[styles.videoContainer].filter(Boolean).join(" ")}
      onMouseEnter={() => screenWidth > 500 && setPauseIconVisible(true)}
      onMouseLeave={() => screenWidth > 500 && setPauseIconVisible(false)}
      onClick={() => screenWidth < 500 && setPauseIconVisible((v) => !v)}
    >
      {!videoIsPlaying && (
        <div onClick={handlePlayVideo} className={styles.playIconContainer}>
          <PlayIcon className={styles.playIcon} fill="white" />
        </div>
      )}
      {videoIsPlaying && pauseIconVisible ? (
        <div onClick={handlePauseVideo} className={styles.pauseIconContainer}>
          <PauseIcon className={styles.pauseIcon} fill="white" />
        </div>
      ) : null}
      {!videoIsPlaying && !videoIsPaused && screenWidth > 500 ? (
        <img
          src="./images/cover_video_desktop.jpg"
          className={styles.coverImage}
        />
      ) : null}
      {!videoIsPlaying && !videoIsPaused && screenWidth < 500 ? (
        <img src="./images/vera_verticale1.jpg" className={styles.coverImage} />
      ) : null}
      {screenWidth > 500 ? (
        <video
          playsInline
          onEnded={() => endedCallback()}
          ref={vidRef}
          src="./square_video_desktop.mp4"
          className={styles.video}
        ></video>
      ) : (
        <video
          playsInline
          onPlay={() => setPauseIconVisible(false)}
          onEnded={() => endedCallback()}
          ref={vidRef}
          src="./vertical_video_mobile.mp4"
          className={styles.video}
        ></video>
      )}
    </div>
  );
};

export default Video;
